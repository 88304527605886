import React from "react"
import { SimpleForm, TabbedForm, Filter, ReferenceManyField, ReferenceField, NumberInput, SelectInput, AutocompleteInput, SelectArrayInput, ResettableTextField, Toolbar, Tab, Pagination} from "ra-ui-materialui";
import { List, Layout} from "ra-ui-materialui"
import Menu from "./Menu"
import { AppBar, Edit } from 'ra-ui-materialui';
import PropTypes from 'prop-types';

function extend(klass, props) {
  klass.defaultProps = Object.assign(klass.defaultProps || {}, props)
}

const variant = "outlined"

extend(SimpleForm, { variant })
extend(TabbedForm, { variant })
extend(AutocompleteInput, { variant, })
extend(Filter, { variant })
extend(ResettableTextField, { variant })
extend(NumberInput, { variant })
extend(Toolbar, { margin: "normal" })
extend(Tab, { contentClassName: "tabContent" })
extend(Pagination, { rowsPerPageOptions: [], perPage: 50 })
extend(ReferenceManyField, { perPage: 50 })
extend(List, { perPage: 50, bulkActionButtons: false })
extend(SelectInput, {emptyText: '\u00A0' })
extend(SelectArrayInput, { variant })

ReferenceField.propTypes = {
  ...ReferenceField.propTypes, 
  translateChoice: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]) 
}
extend(Edit, { undoable: false, })


const MyAppBar = (props) => <AppBar {...props}  color="primary" />

export default function AppLayout(props){
  return <Layout {...props} appBar={MyAppBar} menu={Menu} />
}

