import { merge, includes, fromPairs} from "lodash"
import englishMessages from "ra-language-english"
import domainMessagesEn from "i18n/en-US"
import { resolveBrowserLocale } from "ra-core"
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { parse } from "query-string"

const messages = {
  "en-US": merge({}, englishMessages, domainMessagesEn),  
}
const locales = Object.keys(messages)
const fallBackLocales = {
  uk: "en-US"
}
function getUrlLocale() {
  try {
    return parse(document.location.search).locale
  } catch {
    return null
  }  
}
function normalizeLocale(locale) {
  const lang = locale => locale.split("-")[0]

  if (includes(locales, locale)) {
    return locale
  }
  const extendedLocales = {
    ...fallBackLocales,
    ...fromPairs(locales.map(locale => [lang(locale), locale]))
  }
  const fallbackLocale = extendedLocales[lang(locale)]

  return fallbackLocale || locales[0]
}
export function getDefaultLocale() {
  let locale = getUrlLocale() || localStorage.getItem("locale") || resolveBrowserLocale()
  return normalizeLocale(locale)  
}
const i18nProvider = polyglotI18nProvider(locale => messages[normalizeLocale(locale)], getDefaultLocale(), {
  allowMissing: true,
  //onMissingKey: key => isString(key) ? `missing.${key}` : key
  onMissingKey: key => key
})
i18nProvider.getLocales = () => locales
i18nProvider.getDefaultLocale = getDefaultLocale
export default i18nProvider
