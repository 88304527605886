import React, { useCallback, useState } from "react"
import { useDataProvider, useNotify } from "ra-core"
import { Button } from "ra-ui-materialui"
import { DialogActions, Icon, TextField,  DialogContentText} from "@material-ui/core"
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
export default function RejectButton({ record, onSuccess}){
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [rejectedReason, setRejectReason] = useState(record && record.rejectedReason)
  const [isOpen, setDialogOpen] = useState(false)
  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  
  const handleSubmit = useCallback(() => {
    dataProvider
      .execute(`sale-proposals/${record.id}/reject`, { data: { rejectedReason}, options: { method: "PATCH" } })
      .then(onSuccess)
      .catch(e => notify(e.message, {type: "error"}))
  }, [dataProvider, notify, rejectedReason, record.id, onSuccess])

  return <>
    <Button onClick={openDialog} label="Reject"  color="warning" >
      <Icon>backspace</Icon>
    </Button> 
    <Dialog fullWidth open={isOpen} onClose={closeDialog} >
      <DialogTitle>Reject</DialogTitle>
      <DialogContent>
        <DialogContentText>Please provide reject reason, so user can possibly update and fix his input</DialogContentText>
        <TextField required autoFocus margin="dense" fullWidth value={rejectedReason} onChange={(e) => setRejectReason(e.target.value)} ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} label="Reject">
          <Icon>backspace</Icon>
        </Button>
        <Button label="ra.action.cancel" onClick={closeDialog}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  </>
}
