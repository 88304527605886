import React, { useCallback } from "react"
import { Datagrid, List, TextField, BooleanField, ReferenceField, FunctionField, DateField} from "ra-ui-materialui"
import { CheckCircle, Block } from "@material-ui/icons"
import { Button, Icon } from "@material-ui/core"
import { formatPrice } from "lib/helper"
import { useDataProvider, useNotify, useRefresh } from "ra-core"

const Paid = () => <Icon>attach_money</Icon>
const Unpaid = () => <Icon>money_off</Icon>

function ActivateButton({ record, onSuccess }) {
   const notify = useNotify()
   const dataProvider = useDataProvider()
   const onClick = useCallback(() => {
      dataProvider
         .execute(`orders/${record.id}/activate`, { options: { method: "PATCH" } })
         .then(onSuccess)
         .catch(e => notify(e.message, {type: "error"}))
   }, [dataProvider, notify, record.id, onSuccess])

   return !record.active ? <Button  onClick={onClick} label="Publish" >
      <Icon>price_check</Icon>
      Activate
   </Button> : <span />
}

export default function ListOrders(props){
   const refresh = useRefresh()
   return <List {...props}  >
      <Datagrid >
         <TextField label="Plan" source="meta.orderData.name"  />
         <FunctionField label="Price" render={record => formatPrice(record.meta.orderData.amount / 100)} />
         <TextField label="Status" source="status" />
         <BooleanField source="active" TrueIcon={CheckCircle} FalseIcon={Block}  looseValue={true}/>
         <BooleanField label="Paid" source="paidAt" TrueIcon={Paid} FalseIcon={Unpaid} looseValue={true} />
         <ReferenceField basePath="/users" source="UserId" reference="users" link={"show"} >
            <TextField source="name" />
         </ReferenceField>
         <DateField source="expiredAt" />
         <ActivateButton onSuccess={refresh} />
      </Datagrid>
   </List>
}