import config from "config"
import { get} from "lodash"
import jwt from "jsonwebtoken"
import EventEmmitter from "./EventEmitter"
import md5 from "js-md5";

function gravatarUrl(email, size = 32) {
  const hash = email ? md5(email) : "guest"
  return `https://www.gravatar.com/avatar/${hash}?size=${size}&d=mp`
}
class AuthProvider extends EventEmmitter {  
  async getIdentity() {
    const profile = JSON.parse(localStorage.getItem("profile") || "null")  
    return profile
  }
  async setIdentity(profile) {
    localStorage.setItem("profile", JSON.stringify(profile))
  }
  async getToken() {
    const { accessToken, refreshToken } = (await this.getIdentity()) || {}
    if(accessToken) {
      const {exp} = jwt.decode(accessToken)
      if((exp - 30) * 1000  < Date.now()) {
        await this.refresh({refreshToken})
        return get(await this.getIdentity(), "accessToken")
      }
      return accessToken
    }
    return null
  }  
  async getRefreshToken() {
    return get(await this.getIdentity(), "refreshToken")
  }
  async setAccount(accessToken, refreshToken) {
    const account = jwt.decode(accessToken)
    const identity = {
      id: account.id,
      email: account.email,
      fullName: account.name,
      permissions: account.permissions,
      accessToken,
      refreshToken
    }
    await this.setIdentity(identity)
    const profile = await this.fetchUser(account.id)
    identity.avatar = profile.avatarUrl || gravatarUrl(profile.email)
    await this.setIdentity(identity)
    return identity
  }
  async fetchUser(id) {
    return fetch(new Request(`${config.apiUrl}/users/${id}`, {
      headers: new Headers({
        "Content-Type": "application/json",
        ...await this.getAuthHeaders()
      }),
    })).then(response => {
      if (response.status < 200 || response.status >= 300) {
        const err = new Error(response.statusText || `Status ${response.status}`)
        err.status = response.status
        throw err
      }
      return response.json()
    })
  }

  async getAuthHeaders() {
    return ({ 
      Authorization: `Bearer ${await this.getToken()}` 
    })
  }
  async refresh(params) {
    const { refreshToken } = params
    const request = new Request(`${config.apiUrl}/users/refresh`, {
      method: "POST",
      body: JSON.stringify({ refreshToken }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    return fetch(request).then(response => {
      if (response.status < 200 || response.status >= 300) {
        const err = new Error(response.statusText || `Status ${response.status}`)
        err.status = response.status
        throw err
      }
      return response.json()
    })
      .then(({ accessToken, refreshToken }) => this.setAccount(accessToken, refreshToken))
  }
  async login(params){
    const { username, password } = params
    const request = new Request(`${config.apiUrl}/users/login`, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    return fetch(request).then(response => {   
      if (response.status < 200 || response.status >= 300) {
        const err = new Error(response.statusText || `Status ${response.status}`)
        err.status = response.status
        throw err
      }
      return response.json()
    })
    .then(({ accessToken, refreshToken }) => this.setAccount(accessToken, refreshToken))
  }
  async logout() {
    this.setIdentity(null)
    setTimeout(() => {
      if (document.location.pathname !== "/login") {
        document.location = "/login"
      }
    }, 2000)
    return Promise.resolve("/login") 
  }
  async checkAuth() {
    return await this.getToken() ? Promise.resolve() : Promise.reject("/login")
  }
  async checkError(error) {
    return Promise.resolve()
  }
  async getPermissions() {
    const permissions = get(await this.getIdentity(), "permissions", null)
    return permissions ? permissions : []
  }
}
const authProvider = new AuthProvider()
export default authProvider;
