import React from "react"
import { EditView, SimpleForm, TextInput } from "ra-ui-materialui"
import { Loading } from "ra-ui-materialui"
import { useNotify, useUpdate, useLogin, required } from "ra-core"
import { useAccount } from "hooks"
import { passwordConfirmValidate } from "components/validations"

export default function Profile(props) {
  const account = useAccount() || {}

  const notify = useNotify()
  const login = useLogin()
  const [update, { error }] = useUpdate(props.resource, account.id, account)
  if (error) {
    notify(error.message, {type: "error"})
  }
  const save = values => update({ payload: { data: values } }, {
    onSuccess: () => {
      if (values.password) {
        const { password, username } = values
        login({ password, username }, "/profile").then(() => {
          document.location = document.location.href
        })
      }
    },    
  })
  const record = account ? {
    id: account.id,
    email: account.email,
    username: account.username,
    name: account.name,    
  } : {}
  return account && account.id ? <EditView {...props} record={record} save={save} title="Profile">
    <SimpleForm  redirect="/" key={account.id} {...props} >
      <TextInput source="email" disabled />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="password" autoComplete="off" type="password" />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
    </SimpleForm>
  </EditView> : <Loading />
}
