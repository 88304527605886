import React  from "react"
import { SimpleForm, TextInput, Create} from "ra-ui-materialui"
import {email, required} from "ra-core"

export default function CreateUser ({permissions, ...props}) {
  return (
    <Create {...props}  >
      <SimpleForm >
        <TextInput source="name" validate={required()} />
        <TextInput source="email" validate={email()} />
        <TextInput source="password" autoComplete="off" type="password" validate={required()} />
        <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={required()} />
      </SimpleForm>
    </Create>)
}
