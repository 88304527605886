import React, { useCallback } from "react"
import { Datagrid, List as ListControllerView, SelectInput, TextField, Filter, DeleteWithConfirmButton, BulkDeleteButton, SelectField,} from "ra-ui-materialui"
import { makeStyles } from "@material-ui/styles"
import DateField from "components/DateField";
import { useNotify, useRefresh } from "ra-core"
import DefaultActions from "components/DefaultActions"
import {saleModes} from "lib/consts"
import DealView from "./components/DealView";
import PublishButton from "./components/PublishButton";
import RejectButton from "./components/RejectButton";
const modes = [
   { id: "drafts", name: "Drafts" },
   { id: "release", name: "Release" },
]
const useStyles = makeStyles({
   titleField: {
      maxWidth: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   }  
})

const CompanyFilter = ({ ...props }) => {
   return (
      <Filter {...props} margin={"normal"}>
         <SelectInput alwaysOn source="scope" choices={modes} />
      </Filter>
   )
}

const CompanyBulkActionButtons = props => {
   return <BulkDeleteButton undoable={false} {...props} />
}


export default function ListProposals(props){
   const classes = useStyles()
   const refresh = useRefresh()
   const notify = useNotify()

   const refreshWithNotify = useCallback(title => () => {
      refresh()
      notify(title, {type: "info"})
   }, [refresh, notify])
   
   return <ListControllerView filterDefaultValues={{ scope: "release" }} actions={<DefaultActions />} bulkActionButtons={<CompanyBulkActionButtons />} {...props} filters={<CompanyFilter />} sort={{ field: "createdAt", order: "DESC" }} >
      <Datagrid expand={<DealView />}>
         <TextField source="title" cellClassName={classes.titleField} />         
         <SelectField source="mode" choices={saleModes}/>
         <DateField source="publishedAt" />
         <DateField source="rejectedAt" />
         <DeleteWithConfirmButton />
         <PublishButton onSuccess={refreshWithNotify("Publish success")}/>
         <RejectButton onSuccess={refreshWithNotify("Reject success")} />
      </Datagrid>
   </ListControllerView>
}