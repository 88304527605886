import React from "react"
import {  BooleanInput, DateInput, Edit, FileInput, ImageField, SimpleForm, TextInput} from "ra-ui-materialui"
import {required} from "ra-core"
import RichTextInput from "components/RichTextInput"

export default function EditArticle({permissions, ...props}){  
  return <Edit {...props} >
    <SimpleForm >
      <TextInput source="title" validate={required()}/>
      <TextInput source="url" validate={required()} />
      <DateInput source="date" />
      <BooleanInput source="featured" />
      <RichTextInput source="shortDescription" validate={required()} />      
      <RichTextInput source="description" validate={required()} />
      <FileInput source="picture" placeholder="Upload Picture"  >
        <ImageField source="original.url" title={"url"} />
      </FileInput>      
    </SimpleForm>    
  </Edit>
}
