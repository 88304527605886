import { AttachFile } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "1em"
  },
  link: {
    color: "#455a64",
    textDecoration: "none"
  }
}))
export default function Attachment({attachment}) {
  const classes = useStyles()
  return attachment?.original ? <a href={attachment?.original?.url} target="_blank" rel="noreferrer" className={classes.link}>
    <AttachFile fontSize="small" className={classes.icon}/>
    {attachment?.original.name}
  </a> : <span /> 
}