import React from 'react';
import UsersIcon from "@material-ui/icons/AccountBox"
import ArticleIcon from "@material-ui/icons/Description"
import IndustryIcon from "@material-ui/icons/Business"
import CompanyIcon from "@material-ui/icons/BusinessCenter"
import SaleProposalIcon from "@material-ui/icons/MonetizationOn"

import TeamIcon from "@material-ui/icons/People"
import SupportedIcon from "@material-ui/icons/People"
import ContactRequestIcon from "@material-ui/icons/Email"
import OrderIcon from "@material-ui/icons/Receipt"

const menuItems = [
  { name: "companies", permissions: ["admin"], icon: <CompanyIcon /> },  
  { name: "sale-proposals", permissions: ["admin"], icon: <SaleProposalIcon /> },
  
  { name: "contact-requests", permissions: ["admin"], icon: <ContactRequestIcon /> },
  { name: "users", permissions: ["admin"],  icon: <UsersIcon /> },
  { name: "orders", permissions: ["admin"], icon: <OrderIcon /> },

  { name: "articles", permissions: ["admin"], group: "siteContent", icon: <ArticleIcon /> },
  { name: "industries", permissions: ["admin"], group: "siteContent", icon: <IndustryIcon /> },
  { name: "teams", permissions: ["admin"], group: "siteContent",  icon: <TeamIcon /> },
  { name: "supported-items", permissions: ["admin"], group: "siteContent", icon: <SupportedIcon /> },
]

const items = menuItems.map(item => ({ ...item, checkAccount: () => true }))
export {items}
