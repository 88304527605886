import React  from "react"
import { SimpleForm, TextInput, Create, FileInput, ImageField, } from "ra-ui-materialui"
import {required} from "ra-core"
import RichTextInput from "components/RichTextInput"

export default function CreateIndusty (props) {
  return (
    <Create {...props}  >
      <SimpleForm >
        <TextInput source="title" validate={required()} />
        <RichTextInput source="description" validate={required()} />
        <FileInput source="picture" placeholder="Upload Picture"  >
          <ImageField source="original.url" title={"url"} />
        </FileInput>      
      </SimpleForm>
    </Create>)
}
