import React, {useCallback} from "react"
import {useNotify, useUpdate} from "ra-core"
import Switch          from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Icon} from "@material-ui/core"
import { useLazyState } from "hooks";

export default function ActiveSwitch({ record = {}, onUpdate, source, resource, readonly}) {
  const [active, setActive] = useLazyState(record[source])
  const notify = useNotify()
  
  const onSuccess = useCallback(() => {
    notify("notifications.updated", {type: "success"})
    onUpdate && onUpdate()
  }, [onUpdate, notify])
  const [update, { error }] = useUpdate(resource, record.id, { [source]: !active}, {}, { onSuccess })
  
  const onChange = useCallback((event, value) => {
    setActive(value)
    update()
  }, [setActive, update])

  if (error) {
    notify(error.message)
  }
  return readonly ?
    <Icon>{active ? "done" : "clear"}</Icon> :
    <FormControlLabel control={<Switch checked={active} onChange={onChange} />} />  
}