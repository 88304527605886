import React from "react"
import { makeStyles } from '@material-ui/styles';
import {get} from "lodash"
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DateField from "components/DateField"
import { Avatar } from "@material-ui/core";
import ProfileImage from "layout/components/ProfileImage";
import Attachment from './Attachment';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  inline: {
    display: 'inline',
  },
  item: {
    whiteSpace: "pre-wrap"
  }
}))

export default function HistoryLog({messages}){
  const classes = useStyles()
  
  return <>
    <Typography variant="h6">Replies</Typography>
    <List className={classes.root} >
      {messages.map(message =>
        <ListItem disableGutters={true} key={`list${message.id}`}>
        <ListItemIcon>
            <Avatar><ProfileImage profile={message.User} ></ProfileImage></Avatar>
        </ListItemIcon>
         <ListItemText
            primary={get(message.User, "name")}
            secondary={
              <div className={classes.item}>
                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">{message.message}</Typography>
                {message.attachments.map(attachment => <span key={attachment.original.url} >
                  <br />
                  <Attachment attachment={attachment} />
                </span>)}                
                <br/>
                <DateField record={message} source="createdAt"/>
              </div>
            }
            />
        </ListItem>
      )}
     </List>
  </>

}
