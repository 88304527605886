const locale = ({
  resources: {    
    "users": {
      name: "User |||| Users",
      fields: {
        name: "Name",        
      }
    },
    "orders": {
      name: "Order |||| Orders",
      fields: {
        plan: "Plan",
      }
    },
    "contact-requests": {
      name: "Contact Request |||| Contact Requests",
      fields: {
        
      }
    },
    "teams": {
      name: "Team |||| Team",
      fields: {
        name: "Name",
        picture: {
          "thumb.url": "Picture"
        }
      }
    },
    "supported-items": {
      name: "Supported item |||| Supported items",
      fields: {
        picture: {
          "thumb.url": "Picture"
        }
      }
    },
    "articles": {
      name: "Article |||| Articles",
      fields: {
        title: "Title",
        description: "Description",
        picture: {
          "thumb.url": "Picture"
        }
      }
    },
    "companies": {
      name: "Company |||| Companies",
      fields: {
        title: "Title",
        description: "Description",
        picture: {
          "thumb.url": "Picture"
        }
      }
    },
    "sale-proposals": {
      name: "Sale Proposal |||| Sale Proposals",
      fields: {
        title: "Title",
        description: "Description",        
      }
    },
    "industries": {
      name: "Industry |||| Industries",
      fields: {
        title: "Title",        
        description: "Description",
        picture: {
          "thumb.url": "Picture"
        }
      }
    }
  },
  
  tabs: {
    
  },
  titles: {
    exportCSV: "Export CSV",
    exportTSV: "Export TSV",
    forgotPassword: "Forgot Password",    
  },  
  menu: {
    siteContent: "Site Content"
  },
  themes: {
    grey: "Grey",
  },
  
  notifications: {
    updated: "Updated"    
  },
  validation: {
      passwordConfirm: "Not same",
      moreThan: "Value should be more than %{source}",
      lessThan: "Value should be less than %{source}",
      phoneNumber: "Not valid phone number "
  }
})

export default locale