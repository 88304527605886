import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';
import { ExpandLess } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    
    listItem: {
        paddingLeft: theme.spacing(2),
    },
    icon: {
        minWidth: 40
    },
    listItemText: {
        paddingLeft: 0,
        fontSize: '1rem',
        "& p": {
            fontSize: '1rem',
        },
        "& span": {
            fontSize: '1rem',
        }
    },
    
    nested: {
        paddingLeft: theme.spacing(2),
    },

}))

const SubMenu = ({ handleToggle, open, name, icon, children }) => {
    const classes = useStyles()
    const translate = useTranslate()
    return (
        <>
        <ListItem button onClick={handleToggle} className={classes.listItem}>
            <ListItemIcon className={classes.icon} >{icon}</ListItemIcon>
            <ListItemText
                inset
                primary={translate(name)}
                className={classes.listItemText}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div"  disablePadding className={classes.nested}>
                {children}
            </List>
            <Divider />
        </Collapse>
        </>
    )
}
export default SubMenu
