import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import { profileUrl } from 'lib/helper';

const useStyles = makeStyles({
  profile: {
    width: 40,
    height: 40,
  },
  menu: {
    width: 32,
    height: 32,
    borderRadius: 32,
    backgroundColor: "#FFF"
  },
  card: {
    width: 128,
    height: 128,
  },
});
const variants = {
  profile: {
    className: "profile",
    size: 64
  },
  menu: {
    className: "menu",
    size: 32
  },
  card: {
    className: "card",
    size: 128
  }
}

export default function ProfileImage({ profile, variant = "profile" }){
  const classes = useStyles()
  return <CardMedia className={classes[variants[variant].className]} image={profileUrl(profile, variants[variant].size)} />
}
