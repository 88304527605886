import React from "react"
import checkPermissions from "lib/checkPermissions"
import {Resource}         from "ra-core"
import {Route }             from "react-router-dom"
import Users, {Profile}     from "resources/User"
import Articles from "resources/Article"
import Industries from "resources/Industry"
import Companies from "resources/Company"
import SaleProposals from "resources/SaleProposal"
import Teams from "resources/Team"
import Orders from "resources/Order"
import SupportedItems from "resources/SupportedItem"
import ContactRequests from "resources/ContactRequest"

const ResourceFor = ({name, rest, permissions, access = {}, ...props}) => {
  const hasAccess = type => access[type] && permissions ? checkPermissions(permissions, ...access[type]) : true
  const resource = type => (rest[type] && hasAccess(type)) ? rest[type] : null
  return <Resource key={name} name={name}
      show={resource("Show")}
      list={resource("List")}
      edit={resource("Edit")}
      create={resource("Create")} {...props}/>
}

const resources = permissions => {
  return permissions && [
    <ResourceFor name="users" rest={Users} access={{
      List: ["support", "admin"],
      Show: ["support", "admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }}/>,   
    <ResourceFor name="articles" rest={Articles} access={{
      List: ["admin"],
      Show: ["admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }} />,   
    <ResourceFor name="orders" rest={Orders} access={{
      List: ["admin"],
      Show: ["admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }} />,
    <ResourceFor name="industries" rest={Industries} access={{
      List: ["admin"],
      Show: ["admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }} />,   
    <ResourceFor name="companies" rest={Companies} access={{
      List: ["admin"],
      Show: ["admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }} />,   
    <ResourceFor name="sale-proposals" rest={SaleProposals} access={{
      List: ["admin"],
      Show: ["admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }} />,
    <ResourceFor name="teams" rest={Teams} access={{
      List: ["admin"],
      Show: ["admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }} />,   
    <ResourceFor name="supported-items" rest={SupportedItems} access={{
      List: ["admin"],
      Show: ["admin"],
      Edit: ["admin"],
      Create: ["admin"],
    }} />,   
   
    <ResourceFor name="contact-requests" rest={ContactRequests} access={{
      List: ["admin"],
      Show: ["admin"],      
    }} />,   
    <Resource key="messages" name="messages" />,
    <Resource key="conversations" name="conversations" />
  ]
}

const customRoutes = [
  <Route key="profile" path="/profile" render={() => <Profile resource="users" basePath="/users" />} />,  
]
const dashboard = null
export default resources
export {customRoutes, dashboard}