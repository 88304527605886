import React  from "react"
import { SimpleForm, TextInput, Create, FileInput, ImageField, DateInput, BooleanInput} from "ra-ui-materialui"
import {required} from "ra-core"
import RichTextInput from "components/RichTextInput"

export default function CreateArticle ({permissions, ...props}) {
  return (
    <Create {...props}  >
      <SimpleForm >
        <TextInput source="title" validate={required()} />
        <TextInput source="url" validate={required()} />
        <DateInput source="date" />
        <BooleanInput source="featured" />
        <RichTextInput source="shortDescription" validate={required()} />
        <RichTextInput source="description" validate={required()} />
        <FileInput source="picture" placeholder="Upload Picture"  >
          <ImageField source="original.url" title={"url"} />
        </FileInput>
      </SimpleForm>
    </Create>)
}
