import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#455a64"
    },
    secondary: {
      light: '#455a64',
      main: '#795548',
      contrastText: '#ffcc00',
    }
  },
  overrides: {    
    MuiSnackbar: {
      root: {
        overflowWrap: "anywhere"
      }
    }
  } 
});
