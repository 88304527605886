import React, { useCallback, useState } from "react"
import { Datagrid, List as ListControllerView, TextInput, SelectInput, FunctionField, EditButton, TextField, Filter, DeleteWithConfirmButton, BulkDeleteButton,  ReferenceInput,} from "ra-ui-materialui"
import { makeStyles } from "@material-ui/styles"
import DateField from "components/DateField";
import { useNotify, useRefresh } from "ra-core"
import DefaultActions from "components/DefaultActions"
import CompanyView from "./components/CompanyView";
import PublishButton from "./components/PublishButton";
import RejectButton from "./components/RejectButton";
import DrawerConversation from "./components/Drawer"
import { Mail } from "@material-ui/icons";

const useStyles = makeStyles({
   titleField: {
      maxWidth: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   },
   root: {
      width: 345,
      marginRight: 10
   },
   media: {
      minHeight: 240,
      //backgroundSize: "contain"
   },
   cards: {
      display: "flex"
   }
})

const modes = [
   { id: "drafts", name: "Drafts"},
   { id: "release", name: "Release" },
]

const CompanyFilter = ({ ...props }) => {
   return (
      <Filter {...props} margin={"normal"}>
         <TextInput label="Search" source="q" alwaysOn />
         <ReferenceInput source="IndustryId" reference="industries" alwaysOn >
            <SelectInput optionText="title" />
         </ReferenceInput>
         <SelectInput alwaysOn source="scope" choices={modes} />         
      </Filter>
   )
}


const CompanyBulkActionButtons = props => {
   return <BulkDeleteButton undoable={false} {...props} />
}


export default function ListCompanies(props){
   const classes = useStyles()
   const refresh = useRefresh()
   const notify = useNotify()
   const [company, setCompany] = useState()
   const onRowClick = useCallback((id, resource, record) => setCompany(record), [setCompany])
   const onClose = useCallback(() => setCompany(null), [setCompany])
   const onUpdate = data => setCompany({
      ...company,
      ...data
   })
   const refreshWithNotify = useCallback(title => () => {
      refresh()
      notify(title, {type: "info"})
   }, [refresh, notify])
   
   return <>
      <DrawerConversation key={company?.id} record={company} onClose={onClose} onUpdate={onUpdate}  />
      <ListControllerView filterDefaultValues={{ scope: "release" }} actions={<DefaultActions />} bulkActionButtons={<CompanyBulkActionButtons />} {...props} filters={<CompanyFilter />} sort={{ field: "createdAt", order: "DESC" }} >
         <Datagrid expand={<CompanyView />} rowClick={onRowClick}>
            <TextField source="title" cellClassName={classes.titleField} />
            <DateField source="updatedAt" />
            <FunctionField label="Has Replies" render={record => record.hasReplies ? <Mail /> : <span />} />
            <EditButton />
            <DeleteWithConfirmButton />
            <PublishButton onSuccess={refreshWithNotify("Publish success")} />
            <RejectButton onSuccess={refreshWithNotify("Reject success")} />
         </Datagrid>
      </ListControllerView>
   </>
}