import React  from "react"
import { TabbedForm, FormTab, TextInput, Create, FileInput, ImageField, ReferenceArrayInput, SelectArrayInput, } from "ra-ui-materialui"
import {required} from "ra-core"
import RichTextInput from "components/RichTextInput"

export default function CreateIndusty (props) {
  return <Create {...props} >
     <TabbedForm >
        <FormTab label="Main">
          <TextInput source="title" validate={required()} />
          <ReferenceArrayInput source="IndustryIds" reference="industries"  >
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>
         <TextInput fullWidth multiline source="description" validate={required()} />
          <RichTextInput source="extendedDescription" validate={required()} />
          <FileInput source="picture" placeholder="Upload Picture"  >
            <ImageField source="original.url" title={"url"} />
          </FileInput>
        </FormTab>
        <FormTab label="Contacts">
          <TextInput source="email" />
          <TextInput source="website" />
          <TextInput source="phone" />
          <TextInput source="fax" />
        </FormTab>      
      </TabbedForm>
    </Create>
}
