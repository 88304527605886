import React, { useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from '@material-ui/core';



export default function AddComment ({  anchorEl, onClose, onSubmit}) {
  const [value, setValue]  = useState("")
  const onChange  = useCallback(({target: {value}}) => setValue(value), [setValue])
  const onClick   = () => {
    onSubmit(value)
    setValue("")
  }
  return <Dialog fullWidth open={anchorEl} onClose={onClose} >
    <DialogTitle>Reply to User</DialogTitle>
    <DialogContent>
      <DialogContentText>Reply text</DialogContentText>
      <TextField required 
          autoFocus margin="dense" fullWidth 
          multiline={true}
          rows={8}
          value={value} onChange={onChange} ></TextField>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClick} label="Reject">
        <Icon>send</Icon>
      </Button>
      <Button label="ra.action.cancel" onClick={onClose}>
        <CloseIcon />
      </Button>
    </DialogActions>
  </Dialog>
}
