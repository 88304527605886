import React  from "react"
import { SimpleForm, TextInput, Create, FileInput, ImageField} from "ra-ui-materialui"
import {required} from "ra-core"

export default function CreateTeam(props) {
  return (
    <Create {...props}  >
      <SimpleForm >
        <TextInput source="title" validate={required()} />
        <TextInput source="url" validate={required()} />
        <FileInput source="picture" placeholder="Upload Picture"  >
          <ImageField source="original.url" title={"url"} />
        </FileInput>
      </SimpleForm>
    </Create>)
}
