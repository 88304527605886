import React, { useState } from 'react';
import { usePermissions} from "ra-core";
import { DashboardMenuItem, MenuItemLink, Responsive } from "ra-ui-materialui";
import SubMenu from './SubMenu';
import checkPermissions               from "lib/checkPermissions"
import {compact, filter, map, uniq}                        from "lodash"
import {useTranslate,  useAccount}      from "hooks"
import {items}                          from "config/menu"
import { Icon } from '@material-ui/core';


function Items({ menuItems, onMenuClick}) {
  const translate = useTranslate()
  const { permissions } = usePermissions()
  const account = useAccount()
  const filteredItems = menuItems.filter(item => item.checkAccount(account) && checkPermissions(permissions, ...item.permissions))
  
  return filteredItems.map(item => <MenuItemLink
    key={item.name}
    to={item.path || `/${item.name}`}
    primaryText={translate(item.label || `resources.${item.name}.name`, { smart_count: 2 })}
    leftIcon={item.icon}
    onClick={onMenuClick}
  />)
}

function GroupMenu({ title, icon, onMenuClick, group}) {  
  const [isOpen, setIsOpen] = useState()
  
  const menuItems = filter(items, {group: group || null})
  return <>    
    {menuItems.length > 0 &&
      <SubMenu handleToggle={() => setIsOpen(!isOpen)} open={isOpen} name={title} icon={<Icon>{icon}</Icon>}>
        <Items menuItems={menuItems} onMenuClick={onMenuClick} />
      </SubMenu>
    }
  </>
}

const groupIcons = {
  siteContent: "menu_book"
}

export default function Menu({onMenuClick, logout}){
  const translate = useTranslate()
  //const config = storage.getObject("menuConfig")
  const groups = compact(uniq(map(items, "group")))
  
  return <div>
    {' '}
    <DashboardMenuItem dense={false} onClick={onMenuClick} />
    <Items menuItems={items.filter(item => item.group === undefined)} onMenuClick={onMenuClick} />
    {groups.map(group => <GroupMenu key={group} group={group} onMenuClick={onMenuClick} title={translate(`menu.${group}`)} icon={groupIcons[group] || "settings"} />)}
    <Responsive
      small={logout}
      medium={null} // Pass null to render nothing on larger devices
    />
  </div>
}
