import React from "react"
import { Show, SimpleShowLayout, TextField, } from "ra-ui-materialui"

export default function ShowUser({permissions, ...props}){
  return <Show {...props} >
    <SimpleShowLayout>
      <TextField source="email" />
      <TextField source="name" />
      <TextField source="roles" />      
    </SimpleShowLayout>    
  </Show>
}
