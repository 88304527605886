import React from "react"
import { Datagrid, List, TextField, EditButton, ImageField} from "ra-ui-materialui"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
   titleField: {
      maxWidth: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   },
   urlField: {
      maxWidth: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   }
})
export default function ListTeams(props){
   const classes = useStyles()
   return <List {...props}  >
      <Datagrid >
         <ImageField source="picture.thumb.url" />
         <TextField source="name"  />
         <TextField source="jobTitle" />
         <TextField source="description" cellClassName={classes.titleField}/>         
         <EditButton />
      </Datagrid>
   </List>
}