import {stringify} from "query-string"
import { values, each, mapKeys} from "lodash"
function isFile(value) {
  return value && value.rawFile && (value.rawFile instanceof File)
//  return value instanceof File
}
function flat(data) {
  const res = {}
  each(data, (value, key) => {
    if (typeof (value) === "object" && !isFile(value)) {
      Object.assign(res, mapKeys(flat(value), (val, k) => `${key}.${k}`))
    } else {
      res[key] = value
    }
  })
  return res
}

function multipartBody(params) {  
  const data = flat(params) 
  const formData = new FormData()  
  Object.keys(data).map(async key => {
    const value = data[key]
    if (isFile(value)) {
      formData.append(key, value.rawFile)
    } else {
      formData.append(key, value)
    }
  })
  formData.append("_json", JSON.stringify(params))
  return formData
}
function hasFiles(params) {
  const data = flat(params) 
  return values(data).filter(value => isFile(value)).length > 0
}
const setWhere = (url, where) => {
  const search = url.searchParams;
  const filter = JSON.parse(search.get("filter") || "{}")
  Object.assign(filter, {where})
  search.set("filter", JSON.stringify(filter))  
 
}
const setLimitAndOffset = (url, { page = 1, perPage = 10 }) => {
  const search = url.searchParams;
  const filter = JSON.parse(search.get("filter") || "{}")
  Object.assign(filter, {
    limit: perPage,
    offset: ((page - 1) * perPage)
  })  
  search.set("filter", JSON.stringify(filter))  
};
const setOrder = (url, { field = 'createdAt', order = 'DESC' }) => {  
  const search = url.searchParams;
  const filter = JSON.parse(search.get("filter") || "{}")
  Object.assign(filter, {
    order: [[field, order]]
  })     
  search.set("filter", JSON.stringify(filter))  
};


export default function dataProvider(apiUrl, authProvider) {
  const authFetch = async (url, options = {}) => fetch(url, {
    ...options,
    headers: {
      ...(!options.multipart ? { "Content-Type": "application/json"} : {}),
      ...await authProvider.getAuthHeaders()
    }
  }).then(async response => {
    if (response.error) throw new Error(response.error.message)
    try {
      if (response.status !== 204)
        return await response.json()
      else 
        return {}
    } catch (e) {
      return {}
    }    
  })

  const fetchTotal = async (resource, filter) => {
    const url = new URL(`${apiUrl}/${resource}/count`);
    if (filter && Object.keys(filter).length > 0) setWhere(url, filter)
    const response = await authFetch(url.toString())
    return response.count;
  };
  
  return ({
    getList: async (resource, params) => {
      const filter = JSON.parse(JSON.stringify(params.filter || {}))
      const total = await fetchTotal(resource, filter)
      const url = new URL(`${apiUrl}/${resource}`);
      if (filter && Object.keys(filter).length > 0) setWhere(url, filter)
      if (params.pagination) setLimitAndOffset(url, params.pagination);
      if (params.sort) setOrder(url, params.sort);
      const response = await authFetch(url.toString())
      return ({
        data: response,
        total: total
      })
    },
    getManyReference: async (resource, params) => {
      const filter = JSON.parse(JSON.stringify(params.filter || {}))
      filter[params.target] = params.id
      const total = await fetchTotal(resource, filter)
      const url = new URL(`${apiUrl}/${resource}`);
      
      if (filter && Object.keys(filter).length > 0) setWhere(url, filter)
      if (params.pagination) setLimitAndOffset(url, params.pagination);
      if (params.sort) setOrder(url, params.sort);
      const response = await authFetch(url.toString())
      return ({
        data: response,
        total: total
      })
    },
    getMany: async (resource, params) => {
      const url = new URL(`${apiUrl}/${resource}`);
      const filter = { id: params.ids }
      if (filter && Object.keys(filter).length > 0) setWhere(url, filter)
      const response = await authFetch(url.toString())
      return ({
        data: response,
        total: response.length
      })
    },
    getOne: async (resource, params) => {
      const url = new URL(`${apiUrl}/${resource}/${params.id}`)
      const response = await authFetch(url.toString())
      return ({
        data: response
      })
    },
    update: async (resource, params) => {
      const url = new URL(`${apiUrl}/${resource}/${params.id}`)
      const multipart = hasFiles(params.data)
      const body = multipart ? multipartBody(params.data) : JSON.stringify(params.data)

      await authFetch(url.toString(), {
        method: "PATCH",
        multipart,
        body: body
      })
      const response = await authFetch(url.toString())
      return ({
        data: response
      })
    },
    create: async (resource, params) => {      
      const url = new URL(`${apiUrl}/${resource}`)
      const multipart = hasFiles(params.data)
      const body = multipart ? multipartBody(params.data) : JSON.stringify(params.data)

      const response = await authFetch(url.toString(), {
        method: "POST",
        multipart,
        body: body
      })
      return ({
        data: response
      })
    },
    delete: async (resource, params) => {
      const url = new URL(`${apiUrl}/${resource}/${params.id}`)
      const response = await authFetch(url.toString())
      await authFetch(url.toString(), {method: "DELETE"})
      return ({
        data: response
      })
    },    
    updateMany: async (resource, params) => {
      const url = new URL(`${apiUrl}/${resource}`);
      const filter = { id: params.ids }
      if (filter && Object.keys(filter).length > 0) setWhere(url, filter)
      await authFetch(url.toString(), { method: "PATCH", body: JSON.stringify(params.data)})
      return ({
        data: params.ids
      })
    },    
    deleteMany: async (resource, params) => {
      await Promise.all(params.ids.map(id => authFetch(`${apiUrl}/${resource}/${id}`, {
        method: 'DELETE'
      })))
      return ({ data: params.ids })
    },
    fetch: async (path, params = {}) => await authFetch(`${apiUrl}/${path}?${stringify(params.query)}`, params.options),
    execute: async (path, params = {})  => {
      const multipart = hasFiles(params.data)
      const body = multipart ? multipartBody(params.data) : JSON.stringify(params.data)
      const response = await authFetch(`${apiUrl}/${path}`, {
        method: 'POST',
        multipart,
        body,
        ...params.options
      })
      return ({ data: response })
    },
  })
}