import React from "react"
import {  Edit, FileInput, FormTab, ImageField, ReferenceArrayInput, SelectArrayInput, TabbedForm, TextInput,} from "ra-ui-materialui"
import {required} from "ra-core"
import RichTextInput from "components/RichTextInput"

export default function EditIndustry(props){  
  return <Edit {...props}  >
    <TabbedForm >
      <FormTab label="Main">
        <TextInput source="title" validate={required()} />
        <TextInput fullWidth multiline source="description" validate={required()} />
        <ReferenceArrayInput source="IndustryIds" reference="industries"  >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
        <RichTextInput source="extendedDescription" validate={required()} />
        <FileInput source="picture" placeholder="Upload Picture"  >
          <ImageField source="original.url" title={"url"} />
        </FileInput>      
      </FormTab>
      <FormTab label="Contacts">
        <TextInput source="email" />
        <TextInput source="website" />
        <TextInput source="phone" />
        <TextInput source="fax" />
      </FormTab>      
      
    </TabbedForm>    
  </Edit>
}
