import React from "react"
import { Edit, SimpleForm, TextInput} from "ra-ui-materialui"
import {email, required} from "ra-core"
import {passwordConfirmValidate} from "components/validations"
export default function EditUser({permissions, ...props}){  
  return <Edit {...props} >
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={email()} />
      <TextInput source="password" autoComplete="off" type="password" />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
    </SimpleForm>    
  </Edit>
}
