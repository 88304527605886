import React from "react"
import {   Edit, FileInput, ImageField, SimpleForm, TextInput} from "ra-ui-materialui"
import {required} from "ra-core"

export default function EditIndustry(props){  
  return <Edit {...props} >
    <SimpleForm >
      <TextInput source="title" validate={required()}/>
      <TextInput multiline  source="description" validate={required()} />
      <FileInput source="picture" placeholder="Upload Picture"  >
        <ImageField source="thumb.url" title={"url"} />
      </FileInput>      
    </SimpleForm>    
  </Edit>
}
