import React, { useCallback } from "react"
import { useDataProvider, useNotify } from "ra-core"
import {  Button } from "ra-ui-materialui"
import { Icon, } from "@material-ui/core"

export default function PublishButton({ record, onSuccess }){
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const onClick = useCallback(() => {
    dataProvider
      .execute(`sale-proposals/${record.id}/publish`, { options: { method: "PATCH" } })
      .then(onSuccess)
      .catch(e => notify(e.message, {type: "error"}))
  }, [dataProvider, notify, record.id, onSuccess])

  return !record.publishedAt ? <Button onClick={onClick} label="Publish" >
    <Icon>send</Icon>
  </Button> : <span />
}