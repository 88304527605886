

const timeOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false ,
}
const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',  
}
const saleModes = [
  { id: "sale", name: "Selling whole" },
  { id: "share", name: "Selling share" },
  { id: "invest", name: "Looking for investments" },
  { id: "acquisition", name: "Looking for acquisition" },
  { id: "other", name: "Other" }
]
export {
  saleModes,
  timeOptions,
  dateOptions  
}
