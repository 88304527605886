import React, { useCallback, useState} from "react"
import {  BulkDeleteButton, Button, Datagrid, Filter, NullableBooleanInput,   List as ListView,  TextField, ReferenceField, FunctionField} from "ra-ui-materialui"
import { makeStyles } from "@material-ui/styles"
import DateField from "components/DateField"
import {  Icon } from "@material-ui/core"
import { useDataProvider, useNotify, useRefresh } from "ra-core"
import ToogleSwitch from "components/ToogleSwitch"
import DrawerRequest from "./components/Drawer"
import classnames from 'classnames';
import { Mail } from "@material-ui/icons"
const useStyles = makeStyles(theme => ({
   list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
         duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
   },
   listWithDrawer: {
      marginRight: 400,
   },
  message: {
    maxWidth: 200,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
   card: {
      display: 'inline-flex',
   },
   details: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 250
   },
   content: {
      flex: '1 0 auto',
   },
}))

const ListFilter = ({ ...props }) => <Filter {...props} margin={"normal"}>
   <NullableBooleanInput source="archived" alwaysOn falseLabel="Unarchived" trueLabel="Archived" />
</Filter>


const ArchiveButton = ({ selectedIds, onSuccess }) => {
   const notify = useNotify()
   const dataProvider = useDataProvider()
   const onClick = () => dataProvider
      .updateMany(`contact-requests`, { ids: selectedIds, data: { archived: true } })
      .then(onSuccess)
      .catch(e => notify(e.message, {type: "error"}))
   return <Button onClick={onClick} label="Archive" >
      <Icon>archive</Icon>
   </Button>
}

const ListBulkActionButtons = ({ ...props}) => {
  const refresh = useRefresh()
   return <>
      <BulkDeleteButton undoable={false} {...props} />
     <ArchiveButton {...props} onSuccess={refresh}/>
   </>
}


export default function ListItems(props) {
  const classes = useStyles()
  const refresh = useRefresh()
  const [contactRequest, setContactRequest] = useState()
  const onClose = useCallback(() => setContactRequest(null), [setContactRequest])
  const onUpdate = data => setContactRequest({
    ...contactRequest,
    ...data
  })
  const onRowClick = useCallback((id, resource, record) => setContactRequest(record), [setContactRequest])

  return <>
   <DrawerRequest key={contactRequest && contactRequest.id} record={contactRequest} onClose={onClose} onUpdate={onUpdate} />
   <ListView {...props} filterDefaultValues={{ archived: false }} 
      sort={{field: "createdAt", order: "DESC"}} filters={<ListFilter />} 
      bulkActionButtons={<ListBulkActionButtons />}
      className={classnames(classes.list, {
         [classes.listWithDrawer]: contactRequest?.id,
      })}
      >
      <Datagrid rowClick={onRowClick} >
         <DateField source="createdAt" />
         <TextField source="name" />
         <TextField source="email" />         
         <TextField source="message" cellClassName={classes.message} />
         <ReferenceField basePath="/users" source="UserId" reference="users" link={false} >
            <FunctionField render={record => <a target="_blank" rel="noreferrer" href={`mailto:${record.email}`} >{record.name}</a>} />
         </ReferenceField>
         <FunctionField label="Has Replies" render={record => record.hasReplies ? <Mail /> : <span />} />
         <ToogleSwitch source="archived" onUpdate={refresh} />
      </Datagrid>
   </ListView>
  </>
}