// in src/App.js
import React, { useEffect, useState } from "react"
import { Admin} from "react-admin"
import { Loading, NotFound, Logout, Login } from 'ra-ui-materialui';
import { createBrowserHistory } from 'history';
import Layout from "layout/Layout"
import dataProvider from "lib/dataProvider"
import config from "config"
import i18nProvider from "config/i18n"
import resources, { customRoutes, dashboard } from "config/resources"
import authProvider from "./lib/authProvider"
import theme from "themes/grey"
const history = createBrowserHistory()

export default function App(props) {

  const [token, setToken] = useState(authProvider.getToken() || "guest")

  useEffect(() => {
    authProvider.addListener("token", setToken)
    return () => authProvider.removeListener("token", setToken)
  }, [setToken])

  return <Admin key={token} title={"Admin"}
    theme={theme}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    dataProvider={dataProvider(config.apiUrl, authProvider)}
    history={history}
    layout={Layout}
    loading={Loading}
    loginPage={Login}
    catchAll={NotFound}
    logoutButton={Logout}
    dashboard={dashboard}
    customRoutes={customRoutes}
    customSagas={[]}
  >
    {resources}
  </Admin>
}
