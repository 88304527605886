import React from "react"
import { Datagrid, List, TextField, BooleanField, EditButton, UrlField, ImageField} from "ra-ui-materialui"
import DateField from "components/DateField"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
   titleField: {
      maxWidth: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   },
   urlField: {
      maxWidth: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   }
})
export default function ListArticles(props){
   const classes = useStyles()
   return <List {...props}  >
      <Datagrid >
         <ImageField source="picture.thumb.url" />
         <TextField source="title" cellClassName={classes.titleField} />
         <UrlField source="url" target="_blank" cellClassName={classes.urlField} />
         <BooleanField source="featured" />
         <DateField source="publishedAt" />
         <EditButton />
      </Datagrid>
   </List>
}