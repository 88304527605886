import React from "react"
import { Datagrid, List, TextField, EditButton, ImageField} from "ra-ui-materialui"

export default function ListItems(props){
   return <List {...props}  >
      <Datagrid >
         <ImageField source="picture.thumb.url" />
         <TextField source="title"  />
         <TextField source="url" />
         <EditButton />
      </Datagrid>
   </List>
}