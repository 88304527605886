import React from "react"
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardActionArea, Typography, Icon, CardActions, } from "@material-ui/core"
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Col, Row } from "components/Grid"
import { format } from "date-fns"
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({  
  root: {
    width: "50%",
    marginRight: 10
  },
  media: {
    minHeight: 240,
    //backgroundSize: "contain"
  },
  cards: {
    display: "flex"
  }
})

export default function DealView({ record }){
  const classes = useStyles();
  const { title, description, experience, profit, revenue, size, price, customersCount, } = record
  const foundedAt = record.foundedAt ? format(new Date(record.foundedAt), "MMM, yyyy") : ""
  return <div className={classes.cards}>
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          {record.rejectedAt && <Alert severity="error">{record.rejectedReason}</Alert>}
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="div" >
            {description}
            <hr />
            {experience}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="div">
            <Row>
              <Col>
                <ListItem >
                  <ListItemAvatar><Icon>attach_money</Icon></ListItemAvatar>
                  <ListItemText primary="Profit" secondary={`$ ${profit}`} />
                </ListItem>
              </Col>
              <Col>
                <ListItem >
                  <ListItemAvatar><Icon>attach_money</Icon></ListItemAvatar>
                  <ListItemText primary="Revenue" secondary={`$ ${revenue}`} />
                </ListItem>
              </Col>
              <Col>
                <ListItem >
                  <ListItemAvatar><Icon>monetization_on</Icon></ListItemAvatar>
                  <ListItemText primary="Price" secondary={`$ ${price}`} />
                </ListItem>
              </Col>
            </Row>
            <Row>
              <Col>
                <ListItem >
                  <ListItemAvatar><Icon>group</Icon></ListItemAvatar>
                  <ListItemText primary="Size" secondary={size} />
                </ListItem>
              </Col>
              <Col>
                <ListItem >
                  <ListItemAvatar><Icon>business</Icon></ListItemAvatar>
                  <ListItemText primary="Customers" secondary={customersCount} />
                </ListItem>
              </Col>
              <Col>
                <ListItem >
                  <ListItemAvatar><Icon>event</Icon></ListItemAvatar>
                  <ListItemText primary="Founded" secondary={foundedAt} />
                </ListItem>
              </Col>
            </Row>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>

      </CardActions>
    </Card>

  </div>
}
