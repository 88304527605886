import React, {useState, useCallback} from "react"
import {useDataProvider, useNotify} from "ra-core"
import Switch          from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {get} from "lodash"


export default function ToogleSwitch ({ record, onUpdate, source, resource}) {
  const [value, setValue] = useState(get(record, source))
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const onChange = useCallback(() => {
    setValue(!value)
    dataProvider
      .update(resource, { id: record.id, data: { [source]: !value } })
      .then(() => {
        notify("notifications.updated", {type: "success"})
        onUpdate && onUpdate()
      })
      .catch(error => notify(error.message, "error"))    
  }, [value, setValue, notify, record.id, dataProvider, onUpdate, resource, source])
  return <FormControlLabel control={<Switch checked={value} onChange={onChange} />} />
}