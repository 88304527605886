import React from "react"
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardMedia, CardActionArea, Typography, Button as MuiButton, CardActions, } from "@material-ui/core"
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Alert from '@material-ui/lab/Alert';
import { useStateToggle } from "hooks";
import { Email, Phone, Public, } from "@material-ui/icons";
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import { get } from "lodash"
import HtmlField from "components/HtmlField"

const useStyles = makeStyles({  
  root: {
    width: "50%",
    marginRight: 10
  },
  media: {
    minHeight: 240,
    //backgroundSize: "contain"
  },
  cards: {
    display: "flex"
  }
})

export default function CompanyView({ record }){
  const classes = useStyles();
  const { title, email, phone, website, fax, } = record
  const [more, toggleMore] = useStateToggle()
  const source = more ? "extendedDescription" : "description"
  const linkto = url => url.startsWith("http") ? url : `http://${url}`

  
  return <div className={classes.cards}>
    <Card className={classes.root}>
      <CardActionArea>
        {record.rejectedAt && <Alert severity="error">{record.rejectedReason}</Alert>}
        <CardMedia className={classes.media} image={get(record, "picture.thumb.url")} title={title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">{title}</Typography>
          <Typography variant="body2" color="textSecondary" component="div" >
            <HtmlField source={source} record={record} />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <MuiButton color="primary" size="small" onClick={toggleMore} >{!more ? "More" : "Less"}</MuiButton>
      </CardActions>
    </Card>
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">Contacts</Typography>
          <Typography variant="body2" color="textSecondary" component="div">
            <List dense={true}>
              <ListItem >
                <ListItemAvatar><Avatar><Email /></Avatar></ListItemAvatar>
                <ListItemText primary="Email" secondary={email} />
              </ListItem>
              <ListItem >
                <ListItemAvatar><Avatar><Phone /></Avatar></ListItemAvatar>
                <ListItemText primary="Phone" secondary={phone} />
              </ListItem>
              <ListItem >
                <ListItemAvatar><Avatar><Phone /></Avatar></ListItemAvatar>
                <ListItemText primary="Fax" secondary={fax} />
              </ListItem>
              <ListItem >
                <ListItemAvatar><Avatar><Public /></Avatar></ListItemAvatar>
                <ListItemText primary="Website" secondary={website} />
              </ListItem>
            </List>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <MuiButton color="primary" size="small" target="_blank" href={linkto(website)}>View website</MuiButton>
      </CardActions>
    </Card>
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">Industries</Typography>
          {record.Industries && record.Industries.map(industry => <>
            <Chip key={industry.id} label={industry.title} /> <br />
          </>)}
        </CardContent>
      </CardActionArea>
    </Card>

  </div>
}
