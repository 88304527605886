import {useState, useEffect, useRef, useCallback} from "react"
import { useDeepCompareEffect } from 'ra-core/esm/util/hooks';
import useRecord from './use-record';
import useRefreshWithVersion from './use-record';
import useRouter from './use-react-router';
import { useTranslate, useQuery, useSafeSetState, useAuthProvider} from 'ra-core';
import { useForm, useFormState} from 'react-final-form';

const useLazyState = defaultValue => {
  const [value, setValue] = useState(defaultValue)
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue, setValue])
  return [value, setValue]
}

const useAccount = () => {
  const authProvider = useAuthProvider()
  const [account, setAccount] = useState(null)
  
  useEffect(() => {
    authProvider.getIdentity().then(account => setAccount(account))    
  }, [authProvider, setAccount])
  
  return account
}

const useAutoRedirect = defaultValue => {
  const [, , redirect,] = document.location.href.match(/(\?|&)redirect=(.+)(&|$)/) || []
  const [path, ] = useState(redirect || defaultValue || "")
  return path
}
const useStateToggle = defaultValue => {
  const [value, setValue] = useState(defaultValue)
  const toggle = useCallback(() => setValue(!value), [value, setValue])
  return [value, toggle]
}

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value;
  })
  return ref.current;
}

const useCompare = (val) => {
  const prevVal = usePrevious(val)
  return prevVal !== val
}


export {
  useAccount, usePrevious,
  useRecord, useRouter,  
  useLazyState, 
  useForm, useFormState,
  useTranslate, useQuery,
  useStateToggle,
  useCompare, 
  useAutoRedirect, useSafeSetState,
  useRefreshWithVersion,
  useDeepCompareEffect
}
