import React, { useState, useCallback, useEffect, } from 'react';
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import CloseIcon from '@material-ui/icons/Close';
import {Col, Row} from "./Grid"
import ProfileImage from 'layout/components/ProfileImage';
import DateField from "components/DateField"
import AddComment from './AddComment';
import HistoryLog from './HistoryLog';
import { useDataProvider,  } from 'ra-core';
import Attachment from './Attachment';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },  
  message: {
    whiteSpace: "pre-wrap"
  }
}))
const AddCommentButton = ({ setAnchor}) => {
  const onClick = useCallback(event => setAnchor(event.currentTarget), [setAnchor])
  return <IconButton onClick={onClick} aria-describedby="add_comments">
    <InsertCommentIcon />
  </IconButton>
} 


export default function DrawerRequest({ record: contactRequest, onUpdate, onClose}) {
  const classes = useStyles()
  const [anchorComment, setAnchorComment] = useState(null)
  const dataProvider = useDataProvider()

  const [conversation, setConversation] = useState()
  const [messages, setMessages] = useState([])
  const [version, setVersion] = useState(0)
  const refreshDrawer = useCallback(() => setVersion(version + 1), [version, setVersion])
  
  useEffect(() => {
    if (contactRequest?.id) {
      dataProvider
        .getList("conversations", { filter: { TargetType: "ContactRequest", TargetId: contactRequest?.id} })
        .then(({ data }) => setConversation(data[0]))
    }
  }, [dataProvider, contactRequest?.id, setConversation, version])
  
  useEffect(() => {
    if (conversation?.id) {
      dataProvider
        .getList("messages", { filter: { conversation_id: conversation?.id} })
        .then(({ data }) => setMessages(data))
    }
  }, [dataProvider, conversation?.id, setMessages, version])
  
  
  const onAddComment = (message) => {
    dataProvider.execute(`contact-requests/${contactRequest?.id}/reply`, { data: { message }}).then(() => {
      refreshDrawer()
      setAnchorComment(null)
    })    
  }

  if (!contactRequest) {
    return <span />
  }
 

  return <Drawer key={`drawer_${contactRequest.id}`}  
    anchor="right" 
    open={contactRequest.id ? true : false} 
    variant="persistent"
    PaperProps={{ style: { top: 50, width: 400}}}    
    onClose={onClose} 
    >
    <div tabIndex={0} role="button" className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Details</Typography>
        <IconButton onClick={onClose}> <CloseIcon /></IconButton>
      </div>
      <Card className={classes.card}>
        <CardHeader className={classes.cardHeader} 
          avatar={<ProfileImage profile={contactRequest} />}
          action={<AddCommentButton setAnchor={setAnchorComment} />}
          title={contactRequest.name}
          subheader={<DateField source="createdAt" record={contactRequest}/>} >
        </CardHeader>
        <AddComment  anchorEl={anchorComment} onClose={() => setAnchorComment(null)} onSubmit={onAddComment} />
        <CardContent className={classes.cardContent}>
          <Row>
            <Col>
              <Typography variant="body1" className={classes.message}>{contactRequest.message}</Typography>
              {contactRequest.attachment && <Attachment attachment={contactRequest.attachment}/>}
            </Col>
          </Row>
          <Row>
            <Col>
              <HistoryLog messages={messages}/>
            </Col>
          </Row>    
        </CardContent>
      </Card>
    </div>
  </Drawer>
}
