import React from "react"
import {TextField, Datagrid, List,  EditButton, Filter, NullableBooleanInput} from "ra-ui-materialui"
import ActiveSwitch from "resources/components/ActiveSwitch"
import checkPermissions from "lib/checkPermissions"
import ShowLink from "components/ShowLink"
import { useRefresh } from "ra-core"

const UsersFilter = ({ ...props }) => {
   return (
      <Filter {...props}  margin={"normal"}>
         <NullableBooleanInput source="isActive" alwaysOn />
      </Filter>
   )
}

export default function ListUsers({ permissions, ...props}){
   const editable = checkPermissions(permissions, "admin")
   const refresh = useRefresh()
   return <List {...props} filters={<UsersFilter  />} filterDefaultValues={{isActive: true}}>
      <Datagrid >
         <ShowLink source="email" />
         <TextField source="name" />
         <TextField source="roles" />
         <ActiveSwitch source="isActive" sortable={false} readonly={!editable} onUpdate={refresh}/>
         {editable && <EditButton />}
      </Datagrid>
   </List>
}